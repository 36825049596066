import type {VariantMap} from '@/types';

import Image01, {type Image01Props} from './Image01';
import Image02 from './Image02';
import Image03, {type Image03Props} from './Image03';
import ImageOutcomes from './ImageOutcomes';

export {Image01Props, Image03Props};

const variants: VariantMap = {
  v01: Image01,
  v02: Image02,
  v03: Image03,
  outcomes: ImageOutcomes,
};

export default variants;
