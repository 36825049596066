import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';

import ImageWithCaption, {
  type ImageWithCaptionProps,
} from '../shared/ImageWithCaption';

export default function Image02(props: ImageWithCaptionProps) {
  return (
    <div className="container">
      <Spacer size={props.topSpacerSize} />
      <ImageWithCaption {...props} />
      <Spacer size={props.bottomSpacerSize} />
    </div>
  );
}
