import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import ImageWithCaption, {
  type ImageWithCaptionProps,
} from '@/components/brochureV2/section/Image/shared/ImageWithCaption';

export default function ImageOutcomes(props: ImageWithCaptionProps) {
  return (
    <div className="container">
      <Spacer size="4xl" />
      <ImageWithCaption {...props} />
    </div>
  );
}
