import cn from 'classnames';

import type {ImageComponent, LinkComponent} from '@/types';
import {ColorSchemeEnum} from '@/enums';
import {sectionSchemeClasses} from '@/stylesheets/brochureV2/colorSchemes';
import {getButtonTheme} from '@/components/brochureV2/primitive/Button/types';
import Section from '@/components/brochureV2/primitive/Section/Section';
import {type SPACER_SIZES} from '@/components/brochureV2/layout/Spacer/Spacer';

import type {ImageWithCaptionProps} from './shared/ImageWithCaption';
import variants, {type Image01Props, type Image03Props} from './variants';

export enum ImageVariant {
  Outcomes = 'outcomes',
  V01 = 'v01',
  V02 = 'v02',
  V03 = 'v03',
}

export interface ImageProps {
  colorScheme?: ColorSchemeEnum;
  contentHtml?: string;
  image?: ImageComponent;
  imageVariant?: ImageVariant;
  imagesWithCaption?: {
    contentHtml?: string;
    image?: ImageComponent;
    link?: LinkComponent;
  }[];
  link?: LinkComponent;
  className?: string;
  topSpacerSize?: (typeof SPACER_SIZES)[number];
  bottomSpacerSize?: (typeof SPACER_SIZES)[number];
  sectionIndex?: number;
}

/**
 * @deprecated
 */
export default function Image({
  className,
  colorScheme,
  contentHtml,
  image,
  imagesWithCaption,
  link,
  imageVariant,
  topSpacerSize,
  bottomSpacerSize,
  ...props
}: ImageProps) {
  const currentScheme = colorScheme || ColorSchemeEnum.Light;
  const currentVariant = imageVariant || ImageVariant.V01;
  const Component = variants[currentVariant];
  if (!Component) {
    console.error(
      `[Image] Provided props don't match Image-${currentVariant} props type`,
    );
  }
  const buttonTheme = getButtonTheme(currentScheme);

  const variant02Props = {
    buttonTheme,
    contentHtml,
    image,
    link,
    topSpacerSize,
    bottomSpacerSize,
  } as ImageWithCaptionProps;

  const variantProps = {
    v01: {
      image,
    } as Image01Props,
    v02: variant02Props,
    v03: {
      buttonTheme,
      imagesWithCaption,
    } as Image03Props,
    outcomes: variant02Props,
  };

  return (
    <Section
      sectionName="Image"
      {...props}
      id={undefined}
      className={cn(className, sectionSchemeClasses[currentScheme])}
    >
      <Component {...variantProps[currentVariant]} />
    </Section>
  );
}
