import cn from 'classnames';

import type {ButtonTheme} from '@/components/brochureV2/primitive/Button/types';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Col, {Start, Span} from '@/components/brochureV2/layout/Grid/Col';
import type {ImageComponent, LinkComponent} from '@/types';

import ImageWithCaption from '../shared/ImageWithCaption';

interface ImageType {
  contentHtml?: string;
  image?: ImageComponent;
  link?: LinkComponent;
}

export interface Image03Props {
  buttonTheme: ButtonTheme;
  imagesWithCaption: ImageType[];
}

export default function Image03({
  buttonTheme,
  imagesWithCaption,
}: Image03Props) {
  const isNumberOfImagesEven = !(imagesWithCaption.length % 2);
  return (
    <>
      <Spacer size="2xl" />
      <Grid>
        {imagesWithCaption.map((node, index) => {
          const isEven = !(index % 2);

          const start = {
            default: Start.One,
            md: isEven ? Start.One : Start.Seven,
          };

          const spans = {
            default: Span.Four,
            sm: Span.Eight,
            md: Span.Six,
          };

          const isLastItem = index === imagesWithCaption.length - 1;
          const isSecondToLastItem =
            index >= 1 && index === imagesWithCaption.length - 2;

          return (
            <Col start={start} span={spans} key={node.image?.srcSet}>
              <ImageWithCaption {...node} buttonTheme={buttonTheme} />
              {/* If number of images is even, we need to remove the space on the last 2 only on desktop */}
              {!isLastItem && (
                <Spacer
                  size="2xl"
                  className={cn({
                    'md:hidden': isSecondToLastItem && isNumberOfImagesEven,
                  })}
                />
              )}
            </Col>
          );
        })}
      </Grid>
      <Spacer size="2xl" />
    </>
  );
}
